import React, { useCallback, useEffect, useState } from 'react';
import {
	Control,
	Controller,
	ControllerProps,
	FieldValues,
	Path,
	useController,
} from 'react-hook-form';
import { compareAsc, formatISO, lightFormat, parse, parseISO } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import {
	Autocomplete,
	AutocompleteProps,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormControlLabelProps,
	FormHelperText,
	FormLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	Switch,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useDebounce } from 'usehooks-ts';
import useApi, { ApiResponse } from '../../services/useApi';
import { TagDto, TagService } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import useProfanityFilter from '../../services/useProfanityFilter';

export type DatePickerInputProps<T extends FieldValues, TInputDate> = Omit<
	DatePickerProps<TInputDate, TInputDate>,
	'value' | 'onChange' | 'renderInput' | 'onAccept' | 'minDate' | 'maxDate'
> & {
	control: Control<T>;
	name: Path<T>;
	label?: string;
	minDate?: string | null;
	maxDate?: string | null;
	required?: boolean;
	readOnly?: boolean;
	rules?: ControllerProps['rules'];
};
export const DatePickerInput = <T extends FieldValues>({
	control,
	name,
	label,
	minDate,
	maxDate,
	required,
	readOnly,
	rules,
	...rest
}: DatePickerInputProps<T, Date>) => {
	const displayFormat = 'MM/dd/yyyy';
	const tryParseDate = (value?: string | null): Date | null => {
		if (!value) {
			return null;
		}
		try {
			return parseISO(value);
		} catch {
			return null;
		}
	};

	const tryFormatDate = (value?: Date | null): string | null => {
		if (!value) {
			return null;
		}
		try {
			return formatISO(value, { representation: 'date' });
		} catch {
			return null;
		}
	};

	const minRule = (value: string | null) => {
		if (!value || !minDate) {
			return true;
		}
		const checkDate = tryParseDate(minDate);
		return (
			!checkDate ||
			compareAsc(parseISO(value), checkDate) >= 0 ||
			`${label ?? 'Field'} must fall on or after ${lightFormat(
				checkDate,
				displayFormat
			)}`
		);
	};

	const maxRule = (value: string | null) => {
		if (!value || !maxDate) {
			return true;
		}
		const checkDate = tryParseDate(maxDate);
		return (
			!checkDate ||
			compareAsc(parseISO(value), checkDate) <= 0 ||
			`${label ?? 'Field'} must fall on or before ${lightFormat(
				checkDate,
				displayFormat
			)}`
		);
	};

	// If a validate function is provided, turn it into an object to merge with min/max
	if (typeof rules?.validate === 'function') {
		rules.validate = { validate: rules.validate };
	}

	return (
		<Controller
			control={control}
			name={name}
			rules={{
				...rules,
				...(required && {
					required: rules?.required || requiredMessage(label),
				}),
				validate: { ...rules?.validate, minRule, maxRule },
			}}
			render={({ field, fieldState }) => (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						{...rest}
						// Parse string into Date
						value={tryParseDate(field.value)}
						onChange={() => {}}
						// Trigger blur event on close in case form validation triggered on blur
						onAccept={(value: Date | null) => {
							field.onChange(tryFormatDate(value));
							field.onBlur();
						}}
						minDate={tryParseDate(minDate) ?? undefined}
						maxDate={tryParseDate(maxDate) ?? undefined}
						readOnly={readOnly}
						renderInput={(params) => (
							<TextField
								{...params}
								required={required}
								label={label}
								// Trigger blur event on blur in case form validation triggered on blur
								onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
									field.onChange(
										tryFormatDate(
											parse(event.target.value, displayFormat, new Date())
										)
									);
									field.onBlur();
								}}
								// Show react hook form validations
								error={!!fieldState.error}
								helperText={fieldState.error?.message}
								inputProps={{
									...params?.inputProps,
									readOnly: readOnly,
								}}
							/>
						)}
					/>
				</LocalizationProvider>
			)}
		/>
	);
};

export type SwitchInputProps<T extends FieldValues> = Omit<
	FormControlLabelProps,
	'control' | 'label'
> & {
	control: Control<T>;
	name: Path<T>;
	readOnly?: boolean;
	label?: string;
};

export const SwitchInput = <T extends FieldValues>({
	control,
	name,
	readOnly,
	label,
	...rest
}: SwitchInputProps<T>) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<FormControlLabel
					value="top"
					label={label}
					control={
						<Switch
							checked={!!field.value}
							// Switch input doesn't have a readonly
							onChange={readOnly ? () => {} : field.onChange}
						/>
					}
					{...rest}
				/>
			)}
		/>
	);
};

export type TextFieldInputProps<T extends FieldValues = FieldValues> = Omit<
	TextFieldProps,
	'name'
> & {
	control?: Control<T>;
	name: Path<T>;
	label?: string;
	required?: boolean;
	readOnly?: boolean;
	rules?: ControllerProps['rules'];
	prefix?: string;
	suffix?: string;
	step?: string;
	min?: string;
	maxNumDecimals?: number;
	noErrorMessage?: boolean;
};

export const TextFieldInput = <T extends FieldValues>({
	control,
	name,
	label,
	required,
	readOnly,
	rules,
	type,
	prefix,
	suffix,
	step,
	min,
	// Only applies when type is number
	maxNumDecimals = 2,
	InputProps = {},
	inputProps = {},
	noErrorMessage = false,
	onChange,
	...rest
}: TextFieldInputProps<T>) => {
	InputProps.readOnly = readOnly;
	inputProps.step = step;
	inputProps.min = min;
	if (prefix) {
		InputProps.startAdornment = (
			<InputAdornment position="start">{prefix}</InputAdornment>
		);
	}
	if (suffix) {
		InputProps.endAdornment = (
			<InputAdornment position="end">{suffix}</InputAdornment>
		);
	}

	const formatNumber = (value: any) => {
		if (type !== 'number' || !value) {
			return value;
		}
		let stringValue = value.toString();
		if (stringValue.includes('.') && !!maxNumDecimals) {
			stringValue = Number(stringValue).toFixed(maxNumDecimals);
		}
		return Number(stringValue);
	};

	return (
		<Controller
			control={control}
			name={name}
			rules={{
				...rules,
				...(required && {
					required: rules?.required || requiredMessage(label),
				}),
			}}
			render={({ field, fieldState }) => (
				<TextField
					{...rest}
					required={required}
					label={label}
					type={type}
					value={field.value ?? ''}
					onChange={(e) => {
						field.onChange(formatNumber(e.target.value));
						if (onChange) {
							onChange(formatNumber(e.target.value));
						}
					}}
					// Trigger blur event on blur in case form validation triggered on blur
					onBlur={field.onBlur}
					// Show react hook form validations
					error={!!fieldState.error}
					// Blank error message to keep height consistent if error messages are shown
					helperText={noErrorMessage ? null : fieldState.error?.message ?? ' '}
					// MUI Input Props
					InputProps={InputProps}
					// HTML Input Props
					inputProps={inputProps}
					inputRef={field.ref}
				/>
			)}
		/>
	);
};

export type AutocompleteInputProps<
	TFieldValues extends FieldValues,
	TOption
> = Omit<
	AutocompleteProps<TFieldValues, boolean, boolean, any>,
	'name' | 'options' | 'loading' | 'renderInput' | 'onChange'
> & {
	name: Path<TFieldValues>;
	valueName?: Path<TFieldValues>;
	control?: Control<TFieldValues>;
	label?: string;
	options?: TOption[];
	optionDisplayMapper?: (option: TOption) => string;
	optionValueMapper?: (option: TOption) => string | number | any;
	optionApiService?: (query?: string) => Promise<ApiResponse<TOption[]>>;
	apiFiltering?: boolean;
	rules?: ControllerProps['rules'];
	required?: boolean;
	readOnly?: boolean;
	onChange?: (option: TOption) => void;
	multiple?: boolean;
	optionCreateMapper?: (input: string) => TOption;
	optionCreateService?: (input: string) => Promise<ApiResponse<TOption>>;
};

type Option = {
	id: string | number;
	name: string;
};
function checkIsOption(option: object): option is Option {
	if (!option) {
		return false;
	}
	if ((option as Option).id && (option as Option).name) {
		return true;
	}
	return false;
}

export const AutocompleteInput = <TFieldValues extends FieldValues>({
	name,
	valueName,
	control,
	label,
	options: providedOptions,
	optionDisplayMapper,
	optionValueMapper,
	optionApiService,
	apiFiltering = false,
	rules,
	required,
	readOnly,
	onChange,
	multiple,
	optionCreateMapper,
	optionCreateService,
	...rest
}: AutocompleteInputProps<TFieldValues, Option | string | any>) => {
	const [options, setOptions] = useState<(Option | string | any)[]>([]);
	const [query, setQuery] = useState<string | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const debouncedQuery = useDebounce<string | undefined>(query, 600);

	const {
		field: { onChange: fieldOnChange },
	} = useController({
		control,
		name: name,
	});

	const {
		field: { onChange: valueOnChange },
	} = useController({
		control,
		// This is kind of a gross workaround to not being able to do conditional hooks, it's not used of valueName isn't set
		name: valueName ?? name,
	});

	const optionDisplayMapperCallback = useCallback(
		(option) => {
			if (optionDisplayMapper) {
				return optionDisplayMapper(option);
			}
			if (!option || typeof option === 'string') {
				return option;
			}
			if (typeof option === 'object' && checkIsOption(option)) {
				return option.name;
			}
			throw new Error(
				'Option display mapper must be provided for custom type.'
			);
		},
		[optionDisplayMapper]
	);

	const optionValueMapperCallback = useCallback(
		(option) => {
			if (optionValueMapper) {
				return optionValueMapper(option);
			}
			if (!option || typeof option === 'string') {
				return option;
			}
			if (typeof option === 'object' && checkIsOption(option)) {
				return option.id;
			}
			throw new Error('Option value mapper must be provided for custom type.');
		},
		[optionValueMapper]
	);

	const handleChange = useCallback(
		async (
			value:
				| TFieldValues
				| NonNullable<string | TFieldValues>
				| (string | TFieldValues)[]
				| null
				| any
		) => {
			if (value && value.inputValue && optionCreateService) {
				value = await optionCreateService(value.inputValue);
			}
			if (multiple && value && optionCreateService) {
				// Technically only one of these should be a create so fine to run in sequence
				for (const [index, item] of value.entries()) {
					if (item && item.inputValue) {
						value[index] = (await optionCreateService(item.inputValue)).data;
					}
				}
			}
			//  Set the value field if applicable as well as the option field
			fieldOnChange(value);
			if (valueName) {
				valueOnChange(optionValueMapperCallback(value));
			}
			if (onChange) {
				onChange(value);
			}
		},
		[
			fieldOnChange,
			multiple,
			onChange,
			optionCreateService,
			optionValueMapperCallback,
			valueName,
			valueOnChange,
		]
	);

	const addCreateOption = useCallback(
		(options: (Option | string | any)[], query?: string) => {
			if (!optionCreateMapper || !query) {
				return;
			}
			// Suggest the creation of a new value
			const isExisting = options.some(
				(option) =>
					query.toLowerCase() ===
					optionDisplayMapperCallback(option).toLowerCase()
			);
			if (!isExisting) {
				options.push({
					inputValue: query,
					...optionCreateMapper(query),
				});
			}
		},
		[optionCreateMapper, optionDisplayMapperCallback]
	);

	const populateOptions = useCallback(
		(options: (Option | string | any)[] | null, query) => {
			const sortedOptions = (options ?? []).sort((a, b) =>
				optionDisplayMapperCallback(a).localeCompare(
					optionDisplayMapperCallback(b)
				)
			);
			addCreateOption(sortedOptions, query);
			setOptions(sortedOptions);

			// If only one option is available regardless of query then default to that option
			// Does not autopopulate once query is specified since the user should determine if the option is correct or change their query
			if (options?.length === 1 && !query) {
				handleChange(options[0]);
				return;
			}
		},
		[addCreateOption, handleChange, optionDisplayMapperCallback]
	);

	// Used to populate the options either from the provided options or a service call
	useEffect(() => {
		if (readOnly) {
			return;
		}
		if (providedOptions) {
			populateOptions(providedOptions, debouncedQuery);
			return;
		}
		if (hasLoaded) {
			return;
		}

		if (optionApiService) {
			(async () => {
				setLoading(true);
				const response = apiFiltering
					? await optionApiService(debouncedQuery)
					: await optionApiService();
				// Sort the options shown
				populateOptions(response.data, debouncedQuery);
				// Only run the api call once and do client side filtering
				setHasLoaded(!apiFiltering);
				setLoading(false);
			})();
		}
	}, [
		debouncedQuery,
		hasLoaded,
		optionApiService,
		providedOptions,
		apiFiltering,
		readOnly,
		populateOptions,
		addCreateOption,
	]);

	return (
		<Controller
			control={control}
			name={name}
			rules={{
				...rules,
				...(required && {
					required: rules?.required || requiredMessage(label),
				}),
			}}
			render={({ field, fieldState }) => {
				return (
					<Autocomplete
						{...rest}
						onInputChange={(_event, value, reason) => {
							// Don't count the initial reset with defaults as a query
							if (reason !== 'reset' || !value) {
								setQuery(value);
							}
						}}
						value={field.value || null}
						loading={loading}
						options={options}
						isOptionEqualToValue={(option, value) =>
							optionValueMapperCallback(option) ===
							optionValueMapperCallback(value)
						}
						getOptionLabel={(option) => optionDisplayMapperCallback(option)}
						onChange={(_event, value, _reason, _details) => {
							handleChange(value);
						}}
						onBlur={field.onBlur}
						readOnly={readOnly}
						// Skip MUI's filtering if done api side
						filterOptions={apiFiltering ? (x) => x : undefined}
						multiple={multiple}
						// In the case where they can create their own option it doesn't have to match an existing option
						freeSolo={!!optionCreateMapper}
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						renderInput={(params) => (
							<TextField
								{...params}
								required={required}
								label={label}
								onBlur={field.onBlur}
								error={!!fieldState.error}
								helperText={fieldState.error?.message}
								inputProps={{
									...params?.inputProps,
									readOnly: readOnly,
								}}
								InputProps={{
									...params?.InputProps,
									startAdornment: (
										<>
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
											{params.InputProps.startAdornment}
										</>
									),
									endAdornment: (
										<>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</>
									),
								}}
							/>
						)}
					/>
				);
			}}
		/>
	);
};

// Can extend to support more types in the future, currently only Skill available
export type TagInputProps<TFieldValues extends FieldValues> = {
	name: Path<TFieldValues>;
	control?: Control<TFieldValues>;
	label?: string;
	rules?: ControllerProps['rules'];
	required?: boolean;
	readOnly?: boolean;
};

export const TagInput = <TFieldValues extends FieldValues>({
	name,
	control,
	label,
	rules,
	required,
	readOnly,
}: TagInputProps<TFieldValues>) => {
	const { callApi } = useApi();
	const { filterProfanity } = useProfanityFilter();

	const searchTags = useCallback(
		async (query?: string) => {
			if (!query) {
				return { data: [], error: null } as ApiResponse<TagDto[]>;
			}
			const searchService = TagService.searchBasicTags;
			return await callApi(searchService(query));
		},
		[callApi]
	);

	const createTag = useCallback(
		async (name: string) => {
			await filterProfanity(name);
			const createService = TagService.createBasicTag;
			return await callApi(
				createService({
					id: uuidv4(),
					name: name,
				})
			);
		},
		[callApi, filterProfanity]
	);

	const optionCreateMapper = useCallback((input: string) => {
		return {
			id: 'New',
			name: `Create Tag: "${input}"`,
		};
	}, []);

	return (
		<AutocompleteInput
			name={name}
			control={control}
			label={label}
			readOnly={readOnly}
			optionApiService={searchTags}
			optionCreateService={createTag}
			optionCreateMapper={optionCreateMapper}
			rules={rules}
			required={required}
			apiFiltering
			multiple
		/>
	);
};

export type RadioGroupInputProps<T extends FieldValues = FieldValues> = Omit<
	TextFieldProps,
	'name'
> & {
	control?: Control<T>;
	name: Path<T>;
	label?: string;
	required?: boolean;
	readOnly?: boolean;
	rules?: ControllerProps['rules'];
	options: { label: string; value: any }[];
	onChange?: (optionValue: any) => void;
	row?: boolean;
};

export const RadioGroupInput = <T extends FieldValues>({
	control,
	name,
	label,
	required,
	readOnly,
	rules,
	options,
	onChange,
	row,
}: RadioGroupInputProps<T>) => {
	return (
		<Controller
			control={control}
			name={name}
			rules={{
				...rules,
				...(required && {
					required: rules?.required || requiredMessage(label),
				}),
			}}
			render={({ field, fieldState }) => (
				<FormControl error={!!fieldState.error}>
					{label && (
						<FormLabel required={required} error={!!fieldState.error}>
							{label}
						</FormLabel>
					)}
					<RadioGroup
						onChange={(e) => {
							if (readOnly) {
								return;
							}
							field.onChange(e.target.value);
							if (onChange) {
								onChange(e.target.value);
							}
							field.onBlur();
						}}
						name={name}
						row={row}
						value={field.value ?? ''}>
						{options.map((option: { label: string; value: any }) => {
							return (
								<FormControlLabel
									control={<Radio size="small" />}
									value={option.value}
									label={option.label}
									key={option.value}
								/>
							);
						})}
					</RadioGroup>
					{fieldState.error?.message && (
						<FormHelperText>{fieldState.error?.message}</FormHelperText>
					)}
				</FormControl>
			)}
		/>
	);
};

const requiredMessage = (label?: string) => {
	return `${label ?? 'Field'} is required`;
};
