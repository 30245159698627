import {
	TalentProfileSummaryDto,
	TeamMemberDto,
	TeamMemberState,
} from '../api';
import { StateDefinitions, StateType } from '../Constants/Dialog';

// Gets an object's property value using a property path that contains dot notation including array indices
export const getObjectProperty = (obj: any, propertyPath: string | null) => {
	return propertyPath
		?.split('.')
		.reduce(
			(prev: any, curr: any) => (prev && prev[curr] ? prev[curr] : null),
			obj
		);
};

export const formatCurrency = (value: number | null): string => {
	const currencyFormatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});
	return value ? currencyFormatter.format(value) : 'N/A';
};

export const greaterThanZero = (value?: number | null) => {
	return (
		value === null ||
		value === undefined ||
		value > 0 ||
		'Must be greater than 0'
	);
};

export const recursiveTrim = (objectToTrim: any) => {
	if (typeof objectToTrim === 'string') {
		const trimmedString = objectToTrim.trim();
		// We check for an empty string after trimming here to return a null value instead
		return trimmedString ? trimmedString : null;
	}
	const hasSubPropertiesToIterateAcross = !!Object.keys(Object(objectToTrim))
		.length;
	if (hasSubPropertiesToIterateAcross) {
		for (const property in objectToTrim) {
			if (objectToTrim.hasOwnProperty(property)) {
				objectToTrim[property] = recursiveTrim(objectToTrim[property]);
			}
		}
	}
	return objectToTrim;
};

export const talentProfileToTeamMember = (
	teamId: string,
	talentProfile: TalentProfileSummaryDto,
	inviteState: TeamMemberState
): TeamMemberDto => {
	return {
		teamId: teamId,
		talentProfileId: talentProfile.id,
		preferredName: talentProfile.firstName,
		profilePicUrl: talentProfile.profilePicUrl,
		fullName: talentProfile.firstName + ' ' + talentProfile.lastName,
		role: talentProfile.tagLine,
		hours: talentProfile.desiredHours,
		pay: talentProfile.defaultPayRate,
		inviteState: inviteState,
	};
};

export const getFaviconUrl = (baseUrl: string) => {
	try {
		const url = new URL(baseUrl);
		return `${url.protocol}//${url.hostname}/favicon.ico`;
	} catch (e) {
		console.log('Tried to get favicon url for invalid url: ', baseUrl);
		return undefined;
	}
};

export const setValueAsUserInputOptions = {
	shouldDirty: true,
	shouldTouch: true,
	shouldValidate: true,
};

export const VALID_IMAGE_TYPE_STRINGS = {
	'image/jpeg': [] as string[],
	'image/png': [] as string[],
	'image/webp': [] as string[],
} as { [key: string]: string[] };

/// This is a default profile pic that is used when a user does not have a profile pic, it was generated using the following code:
// // Add Icon Styling based on theme
// const { theme, colorGetter } = useContext(ThemeContext);
// const circleFillColor = colorGetter(
// 	theme === 'light' ? 'gray.400' : 'gray.500'
// );
// const plusColor = colorGetter(theme === 'light' ? 'gray.100' : 'gray.700');
// const defaultProfilePicUrl = encodeSvg(
// 		<IoMdPerson
// 		// natural dimensions should be 0 0 512 512, but we try to zoom in
// 		viewBox="32 0 448 448"
// 		style={{
// 			width: '7rem',
// 			height: '7rem',
// 			backgroundColor: plusColor,
// 		}}
// 		fill={circleFillColor}
// 		className=""
// 	/>
// );
// Render that string in the browser and use it to populate the variables below in case of theme changes

// Safari doesn't like new lines in image src
export const defaultLightProfilePicUrl =
	"data:image/svg+xml, <svg stroke='currentColor' fill='%23a1a1aa' stroke-width='0' viewBox='32 0 448 448' style='width:7rem;height:7rem;background-color:%23f4f4f5' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg' > <path d='M256 256c52.805 0 96-43.201 96-96s-43.195-96-96-96-96 43.201-96 96 43.195 96 96 96zm0 48c-63.598 0-192 32.402-192 96v48h384v-48c0-63.598-128.402-96-192-96z' > </path> </svg>";
export const defaultDarkProfilePicUrl =
	"data:image/svg+xml, <svg stroke='currentColor' fill='%2371717a' stroke-width='0' viewBox='32 0 448 448' style='width:7rem;height:7rem;background-color:%233f3f46' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg' > <path d='M256 256c52.805 0 96-43.201 96-96s-43.195-96-96-96-96 43.201-96 96 43.195 96 96 96zm0 48c-63.598 0-192 32.402-192 96v48h384v-48c0-63.598-128.402-96-192-96z' > </path> </svg>";

export const displayCamelCase = (input: string) => {
	return (
		input
			// insert a space before all caps
			.replace(/([A-Z])/g, ' $1')
			// uppercase the first character
			.replace(/^./, function (str) {
				return str.toUpperCase();
			})
	);
};

export const getStateName = (
	state: string,
	type?: StateType,
	isBuyer?: boolean
): string => {
	type = type ?? StateType.Generic;
	// Get type specific display tied to buyer or talent
	let display =
		isBuyer === undefined
			? null
			: StateDefinitions[type]?.[state]?.[isBuyer ? 'buyerName' : 'talentName'];

	if (display) {
		return display;
	}
	// Get type specific generic display
	display = StateDefinitions[type]?.[state]?.genericName;
	if (display) {
		return display;
	}
	return type === StateType.Generic
		? // If display isn't defined just pretty up the backend state
		  displayCamelCase(state)
		: // Get generic display
		  getStateName(state, StateType.Generic, isBuyer);
};

export const getStateInfo = (
	state: string,
	type?: StateType,
	isBuyer?: boolean
): string | undefined => {
	type = type ?? StateType.Generic;
	// Get type specific display tied to buyer or talent
	let display =
		isBuyer === undefined
			? null
			: StateDefinitions[type]?.[state]?.[isBuyer ? 'buyerInfo' : 'talentInfo'];

	if (display) {
		return display;
	}
	// Get type specific generic display
	display = StateDefinitions[type]?.[state]?.genericInfo;
	if (display) {
		return display;
	}
	return type === StateType.Generic
		? undefined
		: // Get generic display
		  getStateInfo(state, StateType.Generic, isBuyer);
};
